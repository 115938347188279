<template>
  <!-- <v-app :style="{ background: $vuetify.theme.themes.dark.background }"> -->
  <div>
    <v-progress-linear color="indigo" height="10" indeterminate v-if="loadingTable">
    </v-progress-linear>
    <v-card elevation="9" rounded="xl" class="my-9 mx-9 mb-3 pa-3" v-if="!loadingTable">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="3" class="border-left overflow-y-auto msg-window">
            <v-list two-line color="rgba(0,0,0,0)">
              <v-list-item-group v-model="selected" active-class="blue lighten-4">
                <template v-for="(conversation, index) in conversations">
                  <v-list-item :key="index">
                    <v-list-item-avatar>
                      <!-- <v-img
                        :src="'https://cdn.vuetifyjs.com/images/lists/1.jpg'"
                      ></v-img> -->
                      <v-chip v-if="numOfUnreadMsgs(conversation.id) > 0">{{ numOfUnreadMsgs(conversation.id)
                        }}</v-chip>
                    </v-list-item-avatar>
                    <template>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          conversation.name
                        }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-text="lastMessage(conversation.id)"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                  <v-divider :key="conversation.id"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col v-if="selected != undefined" cols="12" sm="9" class="msg-window">
            <div class="conversation-title py-3">
              <posterInfo :uid="conversationTitle" :label="' '" :key="conversationTitle" :iconColor="'white'" />
            </div>

            <div v-if="selected != undefined" flex :style="'display:flex; flex-direction: column'">
              <div v-for="msg in filteredMsgs(conversations[selected].id)" :key="msg.id" :color="'rgba(0, 0, 0, 0)'"
                flat @mouseover="readMessage(msg)" :style="msg.from != UID ? 'align-self: end' : 'align-self: start'
                  ">
                <v-spacer v-if="msg.from != UID"></v-spacer>
                <v-card rounded="xl" class="my-3 mr-2" :style="{
                  background:
                    msg.from === UID ? 'white' : msg.unread === true ? 'rgb(255, 199, 50)' : 'rgb(168, 199, 225)',
                }">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <p class="my-3" v-html="msg.msg_content"></p>
                      <v-list-item-subtitle>
                        <v-icon dense v-if="msg.unread === false">mdi-check-all</v-icon>
                        {{ msg.timestamp | date("date") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-menu offset-y right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" icon>
                            <v-icon dense>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item v-if="msg.from === UID" @click="removeMessage(msg.id)">
                            <v-list-item-title> מחק </v-list-item-title>
                          </v-list-item>
                          <v-list-item v-if="msg.from != UID" @click="UnReadMessage(msg)">
                            <v-list-item-title> חדש </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-icon>
                  </v-list-item>
                </v-card>
                <v-spacer v-if="msg.from === UID"></v-spacer>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="pt-1" v-if="selected != undefined">
          <!-- <v-spacer></v-spacer> -->
          <v-col cols="12" sm="12" class="my-3">
            <v-app-bar color="rgba(0,0,0,0)" flat>
              <v-text-field rounded v-model="message" :append-outer-icon="'mdi-send'" filled
                clear-icon="mdi-close-circle" clearable label="הודע חדשה" type="text" single-line
                @click:append-outer="sendMessage" @click:clear="clearMessage" @keydown.enter="sendMessage"
                :disabled="disableToSend" class="rtl-icon mx-5"></v-text-field>
            </v-app-bar>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <!-- </v-app> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import fire from "@/main";
import posterInfo from "@/components/info/userInfo.vue";

export default {
  data: () => ({
    selected: undefined,
    myID: "YZI3x6glowbwKYW3a1RtE6hpz3i2",
    users: [],
    conversations: [],
    msgsFromMe: [],
    msgsToMe: [],

    show: false,
    message: "",
    marker: true,
    iconIndex: 0,
    loadingTable: true,
  }),
  components: {
    posterInfo,
  },
  computed: {
    ...mapGetters(["loading", "UID"]),

    disableToSend() {
      if (this.selected === undefined) return true;
      if (this.selected.length === 0) return true;
      return false;
    },
    conversationTitle() {
      if (this.selected === undefined) return "";
      return this.conversations[this.selected].id;
    },
  },
  methods: {
    ifAnyMsgUnread(conversationID) {
      let res = false;
      this.msgsFromMe.forEach((one_msg) => {
        if (one_msg.to === conversationID) {
          if (one_msg.unread) {
            res = true;
          }
        }
      });
      this.msgsToMe.forEach((one_msg) => {
        if (one_msg.from === conversationID) {
          if (one_msg.unread) {
            res = true;
          }
        }
      });
      return res;
    },
    numOfUnreadMsgs(conversationID) {
      let res = 0;
      // this.msgsFromMe.forEach((one_msg) => {
      //   if (one_msg.to === conversationID) {
      //     if (one_msg.unread) {
      //       res++;
      //     }
      //   }
      // });
      this.msgsToMe.forEach((one_msg) => {
        if (one_msg.from === conversationID) {
          if (one_msg.unread) {
            res++;
          }
        }
      });
      return res;
    },
    async getConversations() {
      let authors = [];
      for (let one_msg of this.msgsFromMe) {
        // this.msgsFromMe.forEach( (one_msg) => {
        if (
          authors.filter((v) => {
            if (v.id === one_msg.to) return v;
          }).length === 0
        ) {
          let name = await this.conversationName(one_msg.to);
          authors.unshift({ id: one_msg.to, name: name });
        }
      }
      for (let one_msg of this.msgsToMe) {
        // this.msgsToMe.forEach((one_msg) => {
        if (
          authors.filter((v) => {
            if (v.id === one_msg.from) return v;
          }).length === 0
        ) {
          let name = await this.conversationName(one_msg.from);
          authors.unshift({ id: one_msg.from, name: name });
        }
      }
      this.conversations = authors;
    },
    async conversationName(id) {
      let name = await this.$store.dispatch("getUserByID", { uid: id });
      console.log(name.name);
      return name.name;
    },
    filteredMsgs(author) {
      let res = [];
      this.msgsFromMe.forEach((one_msg) => {
        if (one_msg.to === author) {
          if (one_msg.msg_content) {
            res.unshift(one_msg);
          }
        }
      });
      this.msgsToMe.forEach((one_msg) => {
        if (one_msg.from === author) {
          if (one_msg.msg_content) {
            res.unshift(one_msg);
          }
        }
      });
      res = res.sort(function (a, b) {
        return new Date(b.timestamp) - new Date(a.timestamp);
      });
      return res;
    },
    lastMessage(author) {
      let fm = this.filteredMsgs(author);
      let lfm = fm[0];
      if (lfm && lfm.msg_content) {
        return lfm.msg_content.replace(/<[^>]*>?/gm, "");
      }
      return "";
    },
    sendMessage() {
      if (this.message && this.message != "") {
        let new_msg = {
          from: this.UID,
          to: this.conversations[this.selected].id,
          msg_content: this.message,
          unread: true,
          timestamp: Date(),
        };
        fire.database().ref("messages").push(new_msg);
        this.resetIcon();
        this.clearMessage();
      }
    },
    removeMessage(msgID) {
      console.log("deleting " + msgID);
      fire
        .database()
        .ref("messages/" + msgID)
        .remove();
    },
    readMessage(currentMsg) {
      if (currentMsg.unread === true && currentMsg.from != this.UID) {
        console.log("readin " + currentMsg.id);
        fire
          .database()
          .ref("messages/" + currentMsg.id)
          .update({ unread: false });
      }
    },
    UnReadMessage(currentMsg) {
      console.log("unreading " + currentMsg.id);
      if (currentMsg.unread === false) {
        fire
          .database()
          .ref("messages/" + currentMsg.id)
          .update({ unread: true });
      }
    },
    clearMessage() {
      this.message = "";
    },
    resetIcon() {
      this.iconIndex = 0;
    },
  },
  async created() {
    // await this.$store.dispatch("loading", true);
    this.loadingTable = true;
    let viewMessage = this;

    let itemsRef = await fire
      .database()
      .ref("messages")
      .orderByChild("from")
      .equalTo(this.UID);

    await itemsRef.on("value", (snapshot) => {
      let data = snapshot.val();
      if (data) {
        viewMessage.msgsFromMe.length = 0;
        console.log("got new from...");
        // this.getConversations();
        Object.keys(data).forEach((key) => {
          viewMessage.msgsFromMe.push({
            id: key,
            from: data[key].from,
            to: data[key].to,
            unread: data[key].unread,
            msg_content: data[key].msg_content,
            timestamp: data[key].timestamp,
          });
        });
        this.getConversations();
      }
    });
    let itemsRef2 = await fire
      .database()
      .ref("messages")
      .orderByChild("to")
      .equalTo(this.UID);
    await itemsRef2.on("value", (snapshot) => {
      let data = snapshot.val();
      console.log("got new to...");
      // this.getConversations();
      if (data) {
        viewMessage.msgsToMe.length = 0;
        Object.keys(data).forEach((key) => {
          viewMessage.msgsToMe.push({
            id: key,
            from: data[key].from,
            to: data[key].to,
            unread: data[key].unread,
            msg_content: data[key].msg_content,
            timestamp: data[key].timestamp,
          });
        });
        this.getConversations();
      }
    });
    console.log(this.msgs);
    await this.getConversations();
    this.loadingTable = false;

    // await this.$store.dispatch("loading", false);
  },
};
</script>
<style scoped>
.border {
  border-right: 1px solid grey;
}

.border-left {
  border-left: 1px solid grey;
}

.msg-window {
  height: 59vh;
  /* background-color: brown; */
  /* display: flex; */
  overflow: auto;
}

.conversation-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1em;
  background-color: #1565C0;
  color: white;
}
</style>
<style>
.v-input__prepend-inner .v-icon.notranslate.mdi-send {
  transform: rotate(180deg) !important;
}
.v-icon.mdi-send {
  transform: rotate(180deg) !important;
  transform-origin: center;
}
</style>